/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, memo } from "react";
import {
  Radio,
  Upload,
  message,
  Input,
  Form,
  Select,
  Row,
  DatePicker,
} from "antd";
import {
  LoadingOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import moment from "moment";

import { FetchApi } from "../../../api";
import {
  Validate,
  useForceUpdate,
  FormInput,
  SvgView,
  ButtonCustom,
} from "../../../elements";
import { CommonFunction } from "../../../util/CommonFunction";
import { COLORS, STRINGS, LOGIC, Svgs } from "../../../constants";
import CustomTag from "../../../util/components/CustomTag";
import FilterByStore from "./FilterByStore";
import Auth from "../../../store/Authentication";
import Quill from "../../../elements/Quill";

import "./NotificationStaffForm.scss";

const NotificationStaffForm = memo((props) => {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const [radioValue, setRadioValue] = useState(
    userAdmin.type === "STORE_ADMIN" ? 3 : 2
  );
  const [loadingImg, setLoadingImg] = useState(false);
  const [options, setOptions] = useState([]);
  const [nameFilters, setNameFilters] = useState([]);
  const disabled = userAdmin.type !== "SYSTEM_ADMIN";
  const listStore = props.listStore;

  const notificationForm = useRef();
  const title = useRef("");
  const description = useRef("");
  const content = useRef("");
  const imageUrl = useRef("");
  const storeId = useRef("");

  const timeout = useRef();
  const [formContent] = Form.useForm();
  const forceUpdate = useForceUpdate();

  const fetchStaffs = async (searchText = "") => {
    let result = await FetchApi.getListStaff(
      userAdmin.storeId,
      1,
      50,
      null,
      searchText
    );
    setOptions(result.data || []);
  };
  useEffect(() => {
    fetchStaffs();
    if (props.data.title) {
      title.current = props.data.title;
      description.current = props.data.description;
      imageUrl.current = props.data.imageUrl;
      content.current = props.data.content;
      storeId.current = props.data.storeId;
      setNameFilters(props.data.staffs || []);
      // if (userAdmin.type === "STORE_ADMIN") {
      //   setRadioValue(3);
      // } else {
      setRadioValue(props.data.all ? 2 : props.data.storeId ? 3 : 1);
      // }
      formContent.resetFields();
    }
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [props.data]);

  const onChangeRadio = (index) => {
    storeId.current = "";
    setNameFilters([]);
    setRadioValue(index.target.value);
  };

  const handleUpImage = async (file) => {
    setLoadingImg(true);
    let result = await FetchApi.uploadFile(file);
    if (result?.fileUrl) {
      imageUrl.current = result.fileUrl;
      setLoadingImg(false);
    } else {
      message.warning(STRINGS.upload_img_failed);
      setLoadingImg(false);
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const onChangeHtml = (e) => {
    content.current = e;
  };

  const onSelect = (value, item) => {
    let checkSameId = nameFilters.findIndex((itm) => {
      return itm.user.id == item.user.id;
    });
    if (checkSameId > -1) {
      return;
    }
    setNameFilters([...nameFilters, item]);
  };

  const onRemoveTag = (item) => {
    let arrFilter = nameFilters.filter((itm) => {
      return item.id != itm.value;
    });
    setNameFilters(arrFilter);
  };

  const onSearch = (searchText) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(async () => {
      let result = await FetchApi.getListStaff(
        userAdmin.storeId,
        1,
        50,
        null,
        searchText
      );
      setOptions(result.data || []);
    }, 800);
  };

  const uploadButton = (
    <div>
      {loadingImg ? (
        <LoadingOutlined />
      ) : (
        <PlusCircleOutlined style={{ color: COLORS.primary_3, fontSize: 32 }} />
      )}
      <div className="ant-upload-text">{STRINGS.upload}</div>
    </div>
  );
  const removeImg = () => {
    imageUrl.current = "";
    forceUpdate();
  };

  const validateEndTime = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (moment(value, "DD/MM/YYYY HH:mm").isBefore(moment())) {
        return Promise.reject(STRINGS.end_later_start);
      } else if (
        moment(getFieldValue("startTime")).isBefore(
          moment(value, "DD/MM/YYYY HH:mm")
        )
      ) {
        return Promise.resolve();
      } else {
        return Promise.reject(STRINGS.end_later_start2);
      }
    },
  });

  const onConfirm = async () => {
    try {
      await formContent.validateFields();
      if (radioValue === 1 && nameFilters.length < 1) {
        message.warning(STRINGS.please_add_more_receive);
        return;
      }
      let item = { ...props.data };
      const { endTime, startTime } = formContent.getFieldsValue();
      if (Object.keys(props.data).length < 1) {
        let staffIds = nameFilters.map((item) => item.user.id);
        item.title = title.current;
        item.description = description.current;
        item.content = content.current;
        item.imageUrl = imageUrl.current;
        item.storeId = radioValue === 3 ? storeId.current : null;
        item.all = radioValue === 2;
        item.staffIds = staffIds;
        item.staffs = nameFilters.map((item) => item.user);
        item.endTime = endTime;
        item.startTime = startTime;
        if (userAdmin.type === "STORE_ADMIN" && radioValue === 3) {
          item.storeId = userAdmin.storeId;
        }
        notificationForm.current.setLoading(true);
        const result = await FetchApi.pushNotificationStaff(item);
        if (result.success) {
          // item.id = result.notification.id;
          props.onConfirm && props.onConfirm(item);
          message.success(STRINGS.create_notification_success);
        } else {
          CommonFunction.logError(result);
          notificationForm.current.setLoading(false);
        }
      } else {
        let staffIds = nameFilters.map((item) => item.user?.id || item.id);
        item.title = title.current;
        item.description = description.current;
        item.content = content.current;
        item.imageUrl = imageUrl.current;
        item.storeId = radioValue === 3 ? storeId.current : null;
        item.all = radioValue === 2;
        item.staffIds = staffIds;
        item.staffs = nameFilters.map((item) => item.user || item);
        item.endTime = endTime;
        item.startTime = startTime;
        if (userAdmin.type === "STORE_ADMIN" && radioValue === 3) {
          item.storeId = userAdmin.storeId;
        }
        notificationForm.current.setLoading(true);
        const result = await FetchApi.updatePushNotificationStaff(item);
        if (result.success) {
          item.time = result.notification.time;
          props.onConfirm && props.onConfirm(item);
          message.success(STRINGS.update_notification_success);
        } else {
          CommonFunction.logError(result);
          notificationForm.current.setLoading(false);
        }
      }
    } catch (e) {
      notificationForm.current.setLoading(false);
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  return (
    <FormInput
      title={
        props.data && props.data.title
          ? STRINGS.update
          : STRINGS.create_new_notification
      }
      ref={notificationForm}
      renderButton={false}
      isOpen={true}
      onClose={props.disableModal}
    >
      <Form
        labelCol={{ span: 100 }}
        wrapperCol={{ span: 100 }}
        layout="vertical"
        form={formContent}
      >
        <div className="wrapper-container-noti">
          <div className="header-radio">
            <div className="wrapper-radio">
              <div className="title">{STRINGS.select_received_people}:</div>
              <Radio.Group
                onChange={onChangeRadio}
                value={radioValue}
                size="large"
                disabled={userAdmin.type !== "SYSTEM_ADMIN"}
              >
                <Radio value={1}>{STRINGS.select_users}</Radio>
                <Radio value={2} disabled={userAdmin?.type === "STORE_ADMIN"}>
                  {STRINGS.all}
                </Radio>
                <Radio value={3}>{STRINGS.send_to_store}</Radio>
              </Radio.Group>
            </div>
            {radioValue === 1 && (
              <div className="search-wrapper">
                <Select
                  disabled={props.data && props.data.title ? true : false}
                  showSearch
                  onSearch={onSearch}
                  filterOption={false}
                  onSelect={onSelect}
                  suffixIcon={<SearchOutlined />}
                  placeholder={STRINGS.search_noti_place_holder}
                  optionLabelProp={"title"}
                >
                  {(options || []).map((item) => {
                    return (
                      <Select.Option
                        key={`${item.id}`}
                        value={`${item.id}`}
                        user={item}
                        title={item.name}
                      >
                        <div style={{ borderWidth: 20 }}>
                          <div style={{ fontWeight: "bold" }}>{item.name}</div>
                          {item.birthday && (
                            <div style={{ color: "grey" }}>{item.birthday}</div>
                          )}
                          <div style={{ color: "grey" }}>
                            {item.phoneNumber || ""}
                          </div>
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select>

                {nameFilters.length > 0 && (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {nameFilters.map((item, index) => {
                      return (
                        <CustomTag
                          key={"" + index}
                          data={item?.user || item}
                          onRemove={onRemoveTag}
                          disabled={
                            props.data && props.data.title ? true : false
                          }
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            )}
            {radioValue === 3 && (
              <div style={{ marginBottom: 20 }}>
                <FilterByStore
                  onSearchByStore={(e) => {
                    storeId.current = parseInt(e);
                  }}
                  canDisabled={Object.keys(props.data).length}
                  storeId={storeId.current}
                  listStore={listStore}
                />
              </div>
            )}
          </div>

          <div className="wrapper-content">
            <div className="title-bold">{STRINGS.content_notification}</div>
            <Row>
              <Form.Item
                style={{ width: 220, marginRight: 20 }}
                name="startTime"
                label={STRINGS.delivery_start_time}
                rules={[Validate.emptyContent]}
                initialValue={
                  props.data.startTime ? moment(props.data.startTime) : null
                }
                required
              >
                <DatePicker
                  disabled={disabled}
                  style={{ width: "100%" }}
                  placeholder={STRINGS.delivery_start_time}
                  suffixIcon={Svgs.datepicker()}
                  format={LOGIC.timeFormat}
                  showTime
                  onChange={() => formContent.validateFields(["endTime"])}
                />
              </Form.Item>
              <Form.Item
                style={{ width: 220 }}
                name="endTime"
                label={STRINGS.delivery_end_time}
                initialValue={
                  props.data.startTime ? moment(props.data.endTime) : null
                }
                rules={[Validate.emptyContent, validateEndTime]}
                required
              >
                <DatePicker
                  disabled={disabled}
                  style={{ width: "100%" }}
                  placeholder={STRINGS.delivery_end_time}
                  suffixIcon={Svgs.datepicker()}
                  format={LOGIC.timeFormat}
                  showTime
                />
              </Form.Item>
            </Row>
            <div className="header-content">
              <div style={{ position: "relative" }}>
                <div className="title">{STRINGS.image}</div>
                {imageUrl.current && !disabled && (
                  <button
                    style={{
                      position: "absolute",
                      alignSelf: "flex-end",
                      top: 30,
                      right: 30,
                      width: "32px",
                      height: "32px",
                      borderWidth: 0,
                      backgroundColor: "white",
                      borderRadius: 8,
                      overflow: "hidden",
                      paddingTop: 5,
                      cursor: "pointer",
                    }}
                    onClick={removeImg}
                  >
                    <SvgView svgData={Svgs.delete} />
                  </button>
                )}
                <ImgCrop
                  modalTitle={STRINGS.editImageCrop}
                  modalOk={STRINGS.ok}
                  modalCancel={STRINGS.cancel}
                  rotate
                  aspect={3 / 2}
                >
                  <Upload
                    disabled={disabled}
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={CommonFunction.beforeUpload}
                    customRequest={handleUpImage}
                    onPreview={onPreview}
                  >
                    {imageUrl.current ? (
                      <img
                        src={imageUrl.current}
                        alt="img"
                        style={{ width: "100%", height: 230 }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </ImgCrop>
              </div>
              <div className="inputRight">
                <Form.Item
                  label={STRINGS.title}
                  name="title"
                  required
                  rules={[Validate.emptyContent]}
                  initialValue={props.data.title}
                >
                  <Input
                    disabled={disabled}
                    onChange={(e) => {
                      title.current = e.target.value;
                    }}
                  />
                </Form.Item>
                <div className="ant-input-area">
                  <Form.Item
                    label={STRINGS.short_description}
                    name="description"
                    required
                    rules={[Validate.emptyContent]}
                    initialValue={props.data.description}
                  >
                    <Input.TextArea
                      disabled={disabled}
                      onChange={(e) => {
                        description.current = e.target.value;
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="title">{STRINGS.content}</div>
            <Quill
              disabled={disabled}
              style={{ height: 300, marginBottom: 100 }}
              onChange={onChangeHtml}
              value={props.data.content}
            />
          </div>
        </div>
        {!disabled && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ButtonCustom
              isCancelButton
              style={{ marginRight: 10 }}
              onClick={() => notificationForm.current.hide()}
            >
              {STRINGS.cancel}
            </ButtonCustom>
            <Form.Item style={{ margin: 0 }}>
              <ButtonCustom onClick={onConfirm}>
                {props.data && props.data.title ? STRINGS.update : STRINGS.save}
              </ButtonCustom>
            </Form.Item>
          </div>
        )}
      </Form>
    </FormInput>
  );
});

export default NotificationStaffForm;
