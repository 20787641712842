import moment from "moment";

const STRINGS = {
  timeOfApp: "(アプリ表示用)",
  timeOfWeb: "(予約受付用)",
  staff_NG_form: "担当者",
  reception_staff: "受付スタッフ",
  no_data: "データなし",
  ok: "OK",
  close: "閉じる",
  editImageCrop: "映像の編集",
  change: "変更",
  confirm_password: "確認パスワード",
  new_password: "新しいパスワード",
  old_password: "現在パスワード",
  profile: "プロフィール",
  change_password: "パスワードの変更",
  update_profile_completed: "プロフィールの更新が完了しました。",
  update_profile_error: "プロフィールの更新ができません。",
  change_avatar: "アバターの変更",
  email: "メール",
  email_existed: "メールアドレスは既に存在しています",
  birthday: "生年月日",
  done_successful: "正常に完了しました。",
  staff_add_point: "特典付与",
  //validate
  email_error: "メールアドレスのフォーマットが正しくありません。",
  fill_username: "ユーザー名を入力してください",
  fill_password: "パスワードを入力してください",
  upload_img_failed: "写真が変更できません。",
  network_error:
    "ネットワークに接続できませんでした。しばらくたってから再度お試しください。",
  server_error:
    "通信エラーが発生しました。しばらく時間をおいて再度接続してください。",
  please_type_password: "パスワードを入力してください。",
  please_fill_required_fields: "必修項目を入力してください。",
  endtime_greater_than_starttime:
    "終了時間が開始時間より小さく設定してください。",
  something_wrong: "エラーが発生しました。もう一度やり直してください。",
  this_field_is_required: "本項目は必須です。",

  //common-alert
  registered_common: "登録が完了しました。",
  confirm_delete_common: "削除します。よろしいですか？",
  deleted_common: "削除が完了しました。",
  deleted_user: "ユーザーが削除されました",
  reset_field: "リセット",
  confirm_save_common: "保存します。よろしいですか？",
  saved_common: "保存が完了しました。",
  confirm_cancel_common: "予約をキャンセルします。よろしいですか",
  canceled_common: "予約がキャンセルされました。",
  //filter
  search_by_store: "店舗で検索",
  search_place_holder: "ユーザー名・メール・電話番号で検索",
  search_noti_place_holder: "スタッフ名・メール・電話番号で検索",
  all: "すべて",
  add_new: "新規作成",
  only_jpg_png: "JPG/PNGのみ",
  smaller_than_2mb: "映像のサイズは２MB以内です。",
  text_confirm_delete_img: "この映像を削除します。よろしいですか。",
  male: "男性",
  female: "女性",
  type: "種類",
  add_more_course: "時間・料金を増やす",
  please_check_your_email: "メールをチェックしてください。",
  email_was_not_registered: "メールが登録されていません。",

  //form
  image: "画像",
  title: "タイトル",
  content: "内容",
  config_start_time: "適用期間の設定",
  cancel: "キャンセル",
  save: "保存",
  update: "更新",
  upload: "アップロード",
  delete: "削除",
  delete_success: "削除が完了しました。",
  delete_failed: "削除できません。",
  created_time: "登録日",
  history: "履歴",
  image_required: "画像",
  delivery_start_time: "配信開始日",
  delivery_end_time: "配信終了日",
  is_show_pop_up: "ポップアップ表示",
  name_slide: "タイトル",
  behavior_click: "クリックした時の動作",
  do_nothing: "何もしない",
  detailed_screen_display: "詳細画面表示",
  detailed_info: "詳細のお知らせ",
  display_web: "指定のWebアドレスを表示",
  store_list: "店舗画面",
  staff_name_2: "スタッフ名",
  staff_is_existed: "従業員はすでにシステムに存在しています。",
  phone_is_existed: "この番号はすでに登録されています",
  email_is_existed: "メールが登録されました。",

  user_management: "お客さま",
  mypage_management: "マイページ",
  coupon_management: "クーポン",
  set_the_work_order: "接客順位",
  store_management: "店舗",
  homepage_management: "お知らせ",
  notification_management: "プッシュ通知",
  review_management: "スタッフ評価",
  review_management_1: "(新) スタッフ評価",
  mng_staff_store_2: "スタッフ登録",

  //user-procceess
  user_name: "ユーザー名",
  password: "パスワード",
  username_placeholder: "ユーザー名を入力してください",
  password_placeholder: "パスワードを入力してください",
  login: "ログイン",
  logout: "ログアウト",
  you_forgot_password: "パスワードを忘れた場合",
  login_failed: "登録できません",
  wrong_username_or_password: "ユーザー名またはパスワードは正しくありません。",
  do_u_want_logout: "ログアウトしますか？",
  password_is_incorrect: "パスワードが正しくない",
  has_booking_please_change: "現在の勤務シフトに予約があります。",

  //user-management
  name: "ユーザー名",
  phone_number: "電話番号",
  phone_number_2: "電話",
  address: "住所",
  gender: "性別",
  store: "店舗",
  confirm_delete_user: "を削除します。よろしいですか。", //them ten nguoi dung vao dau
  rank: {
    title: "人気度",
    bronze: "ブロンズ級",
    silver: "シルバー級",
    gold: "ゴールド級",
    platinum: "プラチナ級",
    diamond: "ダイヤモンド級",
    free: "非学会員",
  },

  //mypage-management
  config_infor_display_mypage: "マイページに表示される情報の設定",
  image_member: "プロフィール写真",
  image_platinum: "プラチナ写真",
  image_vip: "ビップ写真",

  //coupon-management
  coupon_image: "クーポン画像",
  coupon_title: "タイトル",
  start_date: "開始日",
  end_date: "終了日",
  store_applied: "利用店舗",
  add_new_coupon: "新規作成",
  confirm_delete_coupon: "を削除します。よろしいですか。",
  input_title_coupont: "タイトルを入力してください。",
  please_input_title: "タイトルを入力してください。",
  please_select_store: "店舗を選択してください。",
  select_one_store: "店舗を選択",
  please_select_end_date: "終了時を選択してください。",
  please_select_start_date: "開始時を選択してください。",
  list_notifications: "お知らせのリスト",
  store_receive_notifications: "通知受信の店舗",
  //store-management
  stt: "接客 順番",
  store_name: "店舗名",
  note: "お知らせ",
  open_time: "開店時間",
  close_time: "閉店時間",
  store_owner: "店長",
  confirm: "更新",
  confirm_delete_store: "を削除します。よろしいですか。",
  longitude: "経度",
  latitude: "緯度",
  description: "詳細",
  sort: "表示順", //並び順
  change_position: "表示順変更",
  store_map: "地図の画像",
  change_position_success: "表示順を変更しました。",
  sorting: "並べ替え",
  password_has_changed: "パスワードが変更されました。",
  password_has_character_and_number:
    "数字、英字をそれぞれ1文字以上含めてください。",
  password_8_to_32: "8文字以上32文字以内で入力してください。",
  password_is_not_matching: "パスワードが統一していません。",
  current_password_wrong: "現在のパスワードが正しくありません。",
  //homepage-management
  config_image_slide: "スライド映像の設定",
  config_news: "お知らせの設定",
  information_image: "お知らせの写真",
  short_description: "詳細",
  confirm_delete_news: "を削除します。よろしいですか。",
  please_enter_the_longitude_format_correctly:
    "経度のフォーマットを正しく入力してください。",
  please_enter_the_latitude_format_correctly:
    "緯度のフォーマットを正しく入力してください。",
  search_by_staff: "スタッフ名で検索",
  //notification-management
  notification_image: "お知らせの画像",
  received_people: "受信者",
  confirm_delete_notification: "を削除します。よろしいですか。",
  create_new_notification: "新規お知らせの作成",
  select_received_people: "配信対象",
  select_users: "受信者指定",
  content_notification: "お知らせの内容",
  send_to_store: "店舗指定",

  //review
  staff: "社員",
  member_id: "会員ID",
  date_remarks: "コメントの日付",
  remarks: "コメント",
  date_service: "利用日",

  create_news_success: "お知らせ作成が完了しました。",
  create_notification_success: "お知らせ作成が完了しました。",
  create_coupon_success: "クーポン作成が完了しました。",

  update_news_success: "お知らせ更新が完了しました。",
  update_notification_success: "お知らせ更新が完了しました。",
  update_coupon_success: "クーポン更新が完了しました。",

  //staff-management
  avatar: "アバター",
  staff_id: "スタッフID",
  staff_change: "修正者ID",
  add_new_staff: "従業員追加",
  config_schedule: "毎週の勤務シフト表作成",
  start_time: "開始時間",
  end_time: "終了時間",
  add_staff_successful: "従業員が新しく追加されました",
  add_staff_failed: "従業員の追加が失敗しました",
  update_staff_success: "従業員情報は更新されました",
  update_staff_failed: "従業員情報の更新が失敗しました",
  confirm_delete_staff: "を削除してもよろしいですか？",
  store_1: "所属店舗①",
  store_2: "所属店舗②",
  store_3: "所属店舗③",
  store_4: "所属店舗④",
  average_score: "平均点",
  procedure_range: "手技範囲",
  self_introduction: "自己紹介",
  duplicate_store: "選択した所属店舗が複製されました。",
  please_select_at_least_1: "店舗を選択してください。",

  //booking
  customer_name: "お客様名",
  time: "時間",
  create_booking_success: "予約が成功しました",
  update_booking_fail: "予約の更新に失敗しました。",
  no_staff_avaliable: "スタッフがいません",
  cancel_booking: "予約キャンセル内容",
  slide_setting: "スライド設定",

  //point-history
  monthly_point_history: "月間ポイント履歴",
  filter_by_time: "年月日で検索",
  grant: "付与",
  point_use: "利用",
  total_price: "利用料金",
  store_wage: "店取分",
  type_grant: "発行方式",

  //gacha
  gacha: "抽選会",
  gacha_list: "賞リスト",
  winning_digits: "当選下ケタ数",
  points_awarded: "付与ポイント数",
  award_name: "名称",
  this_gacha_is_existed: "抽選は既にシステムに存在します。",

  //combo
  course_setting: "コース設定",
  course_price_setting: "その他料金設定",
  app_member: "アプリ会員",
  list_price: "定価",
  category_title: "カテゴリータイトル",
  description_2: "説明",
  category_title_short: "コースの略名",

  //point-reception
  point_provide: "特典付与",
  point_reception: "ポイント受付",
  grant_points: "ポイント付与",
  hidden_in_suggest_booking: " 再案内限定  ",
  use_points: "ポイント利用",
  time_of_visit: "来店年月日",
  staff_in_charge: "担当スタッフ",
  points: "ポイント数",
  reject: "拒否",
  allocation_date: "ポイント受付日時",
  reception_datetime: "受付日時",
  reject_notice: "ポイント付与を拒否します。",
  grant_notice: "ポイント付与を承認します。",
  use_notice: "ポイント利用を承認します。",
  time_confirm: "取得月日",
  discount_amount_yen: "割引金額（円）",
  apply_coupon: "クーポン対象",
  amount_price: "金額",
  //visit-history
  visit_history: "ポイント履歴",
  visit_point: "来店ポイント",
  review_point: "レビューポイント",
  lottery_point: "抽選ポイント",
  review_evaluation_score: "レビュー評価点",
  filter: "フィルター",
  total_store_points: "店舗ポイント合計",
  total_points_used: "ポイント利用合計",
  lottery: "抽選",
  display_price: "店舗合計",
  booking_type_point: "指定ポイント",

  //store-info
  available_link: "空き状況のURL",
  QRCode: "QR コード",
  QR_list: "来店ポイント用QRコード",

  //store-staff-account
  store_staff_account: "アカウント管理",
  rating_average: "30日稼働率",
  work_performance: "稼働率",
  detail: "詳細",

  //store-provide-point
  store_provide_point: "ポイント追加",
  furigana_name: "ふりがな",
  download: "ダウンロード",
  download_actual_schedule: "スケジュールダウンロード",
  download_fixed_schedule: "シフトダウンロード",
  download_expected_schedule: "勤務契約ダウンロード",
  special_point: "特別ポイント",
  download_ALL_booking_in_Today: "予約速報",

  // Staff-manager-schedule
  schedule_adjustment: "スケジュール調整",
  recovery: "回復",
  name2: "指名",

  checkin: "出勤",
  checkout: "退勤",
  early: "早",
  during: "中",
  late: "遅",

  //staff-schedule
  online_reservation_reception: "ネット予約受付",
  working_hours: "勤務時間",
  rest: "休憩",
  minute: "分",
  reservation_time: "予約時間",
  course: "コース",
  price: "料金",
  staff2: "スタッフ",
  situation: "状態",
  reservation_list: "予約一覧",
  total: "合計",
  holiday: "休み",
  rest2: "休憩する",
  sure: "確定",
  please_select_a_reception_staff: "受付スタッフを選択してください。",
  correction_of_work_hours: "出勤時間の修正",
  please_select_a_change_time: "変更時間を選択してください。",
  if_you_press_the_attendance_button_you_will_not_be_able_to_change_it:
    "インタバール設定",
  describle_checkin: "ボタンを押した後は、インターバルは変更できない。",
  add_staff: "スタッフ追加",
  please_select_your_commute_time: "出勤時間を選択してください。",
  please_select_an_attendance_staff: "出勤スタッフを選択してください。",
  time_change: "時間変更",

  combo_color: "コースカラー",
  is_favourite: "おすすめコース",
  is_prolonged: "コースを延長することはできますか？",
  combo_category: "カテゴリー",
  can_combine_with_another_course: "組合せ不可",
  // staff-booking
  next: "次へ",
  step_1: "選択",
  step_2: "時間",
  step_3: "確認",
  tomorrow: "明日",
  num_user: "お客様人数",
  user_num: (i) => {
    if (i === 1) {
      return "代表者様(1人目)";
    }
    return `お連れ様(${i}人目)`;
  },
  des_booking_1: "①いつのご予約ですか？",
  des_booking_2: "②何名様ですか？",
  des_booking_3: "⑤まずは、電話番号を聞きます。",
  des_booking_4: "⑥お名前と電話番号をお願い致します。(後で入力してもOK)",
  des_booking_5: "③コースはお決まりですか？",
  des_booking_6:
    "⑥施術中の過ごし方のご希望はありますか？次の4つからお選び下さい。",
  des_booking_7: "スタッフの指名はありますか？",
  id_user: "IDユ-ザ-",
  name_user_booking: "お名前",
  head: "頭",
  neck: "首",
  shoulder: "肩",
  spine_back: "背中",
  hand: "うで",
  belt: "腰",
  butt: "お尻",
  thighs: "太もも",
  calf: "ふくらはぎ",
  note_title: "特にほぐしてほしい。箇所を選択してください。（複選可)",

  combo_minute: "コース時間はお決まりですか？",
  combo_recommend: "オススメセット",
  password_title: "パスワードを入力",
  app_non_member: "非アプリ会員",
  yen: "円",
  combo_all: "自由にセレクト",
  select: "指名する",
  no_one: "指名しない",
  please_choose_combo: "コースを選択してください。",
  please_select_staff: "スタッフを選択してください",
  staff_gender: {
    male: "男性",
    female: "女性",
    MALE: "男性",
    FEMALE: "女性",
  },
  num_havent_select_course: (i) => `${i}人目がコースを選択していません`,
  wish_time: "希望時間:",
  select_suggest_time: (i) => `次の${i}つから選択してください。`,
  available_time: "予約可能な時間",
  month: "月",
  unavailable_user: "ユーザーは存在しません",
  //fixed schedule
  shift_list: "シフト調整",
  checkin_success: "出勤が成功しました。",
  have_checked_in: "予約がチェックインされました。",
  checkout_success: "退出が成功しました。",
  add_working_hour: "勤務時間の追加",
  please_select_shift: "シフトを選択してください。",
  back: "戻る",
  number_people: "お客様数",
  confirm_book: "ご予約確認",
  confirm_booking_user: (i) => {
    if (i === 1) {
      return `代表者様のご予約確認 \xa0\xa0 (${i}人目)`;
    }
    return `お連れ様のご予約確認 \xa0\xa0 (${i}人目)`;
  },
  confirm_note: "施術中の接客",
  no_suggest_available: "予約が埋まってました。",
  NG_list: "NG リスト",
  manager_cm: "ＣＭ設定",
  shift: "シフト",
  reason: "理由",

  push_to_staff: "スタッフ通知",
  shift_existed: "シフトはすでに存在しました",
  shift_duplicated: "シフトが重なっています。変更できません",
  shift_existed_another_store: "勤務時間は他の店舗と重複していました。",
  customer_info: "お客様情報",
  member: "会員",
  NG_designation: "NG指定",
  cancellation_charge: "キャンセル料",

  canceled_with_contact: "連絡あり",
  cancellation_without_permission: "連絡なし",
  cancellation_at_store: "店舗都合",
  cash_register: "レジ",
  date2: "日付け",
  responsible_person: "担当",
  extension: "延長",

  situation_booking: {
    order: "未施術",
    done: "完了",
    arrive: "施術中",
  },

  now: "今すぐ",
  day_book: "希望日",
  hour_book: "希望時間",
  content_course: "コース",
  remuneration: "報酬",
  working_hours_2: "勤務時間帯",
  attendance_time: "出勤時刻",
  number_of_customer_service: "接客数",
  number_of_names: "指名数",
  people: "人",
  discount: "店への経費",
  total_cong_nv: "報酬合計",
  fee_store: "店への経費",
  leave_time: "退勤時刻",
  people_2: "振替",
  next_work_status: "欠勤状況",
  bonus_staff_work: "出勤スタッフの追加",
  staff_name_3: "施術担当",
  day_ordered: "施術日",
  day_assigned: "受付日",
  staff_assigned: "受付担当",

  //staff change history
  type_change_text: "変更内容",
  old_value: "変更前",
  new_value: "変更後",
  change_history: "変更履歴",
  cancel_booking_history: "キャンセル履歴",
  job_change_history: "操作履歴",
  change_working_hour_history: "勤務時間変更",
  change_staff_history: " 担当者変更",
  checkin_booking: "チェックイン",
  registration_date: "登録日",
  favourite_store: "登録店舗",
  is_assign: "指名",
  today_comment: "今日のコメント",
  search_booking: "検索",
  past_medical_history: "お客様情報(疾患や趣味、座り仕事などの情報)",
  edit_information_customer: "情報を編集する",
  edit_not_good: "編集",
  bed: "ベッド数",
  owner_booking: "電話番号を入力してください。",
  hangout_user_booking: (i) =>
    `お連れ様(${i}人目)のおなまえを聞きします。(後で入力可)`,
  bed_full: "シフトがいっぱいです。別の日時に変更してください。",
  bed_full_2: "この時間帯は、空きベッドがありません。",
  change_time_booking: "変更してください。",
  check_in_success: "チェックインしました。",
  check_in_fail_beyond_time:
    "チェックインできません。チェックインが遅すぎます。",
  check_in_fail_before_time: "まだチェックインする時間ではありません。",
  day_go_store: "来店日",
  course_used: "利用コース",
  time_checkin: "チェックIN",
  time_checkout: "チェックOUT",
  createFrom: "予約方法",
  startTimeStaff: "受付時刻",
  pointUserUsed: "ポイント利用",
  pointProvider: "ポイント付与",
  history_go_store: "来店履歴",
  store_code: "店舗コード",
  cancel_fee: {
    cancel_contact: "連絡ありキャンセル、キャンセル料：1000円",
    cancel_by_store: "店の都合でキャンセル、キャンセル料：なし",
    cancel_accompany: (i) => `連絡ありキャンセル、キャンセル料：${i}円`,
    cancel_before_2hours: "店の都合でキャンセル、キャンセル料：なし",
  },
  cancel_no_contact_fee: (i) => `連絡なしキャンセル、キャンセル料：${i}円`,
  price_job: "報酬",
  price_tax: "内税",
  add_new_course: "カテゴリ追加",
  config_menu: "設定項目",
  price_combo_no_member: "非アプリ会員",
  background_disease: "お客様の詳細情報",
  user_banned: "利用禁止",
  city: "県",
  province: "市",
  age: "年齢",
  user_type: "会員種類",
  NG_list_user: "NGスタッフ",
  info_background_disease: "お客様情報",
  customer_type: {
    MEMBER_APP: "アプリ会員",
    MEMBER_NON_APP: "非アプリ会員",
    S_MEMBER: "シニア会員",
    BANNED_MEMBER: "利用禁止",
  },
  search_user_placeholder:
    "ユーザー名・メール・ふりがな・電話番号・会員IDで検索",
  contact_before_15_days:
    "固定シフトの変更は前月15日までに、オーナーに連絡してください。",
  qr_download: "QRコードダウンロード",
  cannot_update_shift: "シフトは編集できません",

  coupon_time: "時間帯",
  coupon_time_start: "時間開始",
  coupon_time_end: "時間終了",
  coupon_day: "曜日",
  coupon_date: "特定の日",
  copy_schedule: "前の月からコピー",
  customer_type_new: {
    X: "ブラック会員",
    R: "非会員",
    P: "プラチナ会員",
    S: "シニア会員",
    A: "アプリ会員",
    D: "ダイヤ会員",
  },
  price_select_gender: "男性指定料金",
  price_late: "深夜料金",
  price_bonus: "男性のオイル追加代金",
  customer_manager_menu: "お客さま",
  group_manager_menu: "各店舗",
  staff_manager_menu: "スタッフ",
  slide_manager_menu: "スライド",
  course_manager_menu: "コース",
  relations_manager_menu: "お知らせ",

  operational_menu_store: "運営",
  work_manager_menu_store: "シフト",
  staff_manager_menu_store: "スタッフ",
  customer_manager_menu_store: "お客さま",
  history_manager_menu_store: "履歴",
  course_manager_menu_store: "コース",
  relations_manager_menu_store: "お知らせ",
  normal_coupon: "普通クーポン",
  birthday_coupon: "誕生日クーポン",
  tax_coupon: "10%割引クーポン",
  first_time_use_service_coupon: "初来店クーポン",
  serve_booking_coupon: "前日予約クーポン",
  coupon_apply_member: "対象会員",
  coupon_shared: "併用可",
  coupon_single: "併用不可",
  coupon_show: "表示",
  coupon_hide: " 非表示 ",

  manager_mail: "メール配信",
  type_mail: "メールタイプ",
  date_created_mail: "作成日時",
  text_edit_mail: "内容の設定",
  cannot_change_type: "このタイプの会員に切り替えることはできません",
  create_D_combo: "非公開",
  store_schedule_des: "シフト変更：前月15日まで",
  designation_fee: "指名料",
  reset_password_staff: " パスワードリセット",
  number_please: "数字で入力してください。",
  number_phone_incorrect: "無効な電話番号です。",
  pay_booking_success: "お会計が完了しました。",
  cannot_rest_because_booking: "予約がありますので、休憩はできません。",
  cannot_rest: "休憩できません。",
  cannot_rest_choose_different_time:
    "この時間では休憩できません。別の時間を指定し直してください",
  free: "無料",
  password_for_this_staff: "本人のパスワードを入力してください。",
  this_user_is_banned: "お客様はもみ徳のご利用を制限されています。",
  name_info_booking: "名前",
  note_booking: "接客",
  des_button_cancel: "キャンセル料を貰ったら押す。",
  note_special_booking: "希望",
  acutal_work_time: "実働時間",
  minutes_late_and_rest_early: "遅刻＋早退",
  not_enough_time: "不足時間",
  bonus_minute: "振替休み\nボーナス",
  rank_up_bonus: "順位上げ\nボーナス",
  actual_wait_time: "実際の\n待機時間",
  config_work_time: "調整待機時間",
  charge_cancel_fee_success: "キャンセル料が支払われました",
  cannot_checkout_because_resting: "休憩中なので、退勤できません",
  cannot_checkout_because_error: "勤務時間外のため、チェックアウトできません ",
  cannot_checkin_because_error: "勤務時間外のため、チェックインできません",
  minute_extra_duplicate: "延長数分は既に設定されました。",
  update_shift_fail: "予約がありますので、勤務時間変更できません。",
  interval_time: "インターバル",
  time_rest_extra: "休憩時間\n(インターバル抜き）",
  money_book: "お会計",
  discount_book: "割引",
  discount_point: "ポイント",
  payment: "お支払い",
  payment_2: "支払い",
  payment_3: "支払い完了",
  payment_des: "※キャンセル料支払い後\n\xa0\xa0レジが押せます。",
  reserve: "予約",
  date_time_book: "予約日時",
  create_from: "予約方法",
  time_receive_booking: "受付時刻",
  time_connect: "来店時刻",
  info_background: "お客様情報\n疾患や趣味・座り仕事などの情報",
  hangout_user: "検索して\nチェックイン",
  edit: "編集する",
  registration: "新規登録",
  download_schedule: "戸締まりダウンロード",
  no_user_found: "検索しましたが、お客様の情報はありません。",
  renew_booking: "予約更新",
  please_no_space: "スペースは空けずに入力してください",
  booking_with_app: "アプリで予約します",
  fill_with_number: "数字で入力してくzださい。",
  please_select_one_noti: "お知らせを選択してください。",
  type_pt_history: "ポイント種類",
  type_pt_use: "利用ポイント",
  search_pt_history_place_holder: "ユーザー名・ふりがな・スタッフ名で検索",
  search_provide_point_place_holder:
    "ユーザー名・メール・ふりがな・電話番号で検索",
  staff_max_3_store: "従業員は最大3店舗にしか所属できます。",
  fill_your_staff_id: "スタッフIDを入力してください。",
  invalid_url: "無効なURL",
  fill_valid_url: "リンク先を入力してください。",
  positive_num: "カウントダウン時間が0よりも大きい整数でなければなりません。",
  invalid_value: "値が無効です",
  invalid_hex_color: "16進数の色を入力してください。",
  invalid_input_value: "入力値が正しくありません",
  rating1: "コリに的中",
  rating2: "力強い",
  rating3: "動作が楽に",
  rating4: "スッキリ",
  rating5: "丁寧な接客",
  rating6: "ワザが凄い",
  break_time: "休憩の回数",
  max_break_time: "休憩の上限時間",
  course_has_applied: "適用コース",
  apply_all_course: "全コース適用",
  hangout_user_cancel: (i) => `お連れ様${i}様`,
  userTypeExplain: [
    {
      id: "D",
      type: "ダイヤ(D)",
      color: "#ff8000",
      des: "2020年以前にVIP会員様+(A)又は(S)会員のお客様。\n(A)会員と同様。一部対応店舗のみ『うつぶせコース』の予約可能。",
    },
    {
      id: "A",
      type: "アプリ(A)",
      color: "#ff8000",
      des: "アプリの会員登録が完了したお客様。ポイントが貯まる。\nアプリ割引(−700円)適用。アプリからの予約限定でポイント利用可能。",
    },
    {
      id: "S",
      type: "シニア(S)",
      color: "#ff8000",
      des: "満60歳以上で、アプリが扱えないお客様。\nアプリ割引(−700円)適用。",
    },
    {
      id: "P",
      type: "プラチナ(P)",
      color: "#497d37",
      des: "アプリの登録なし。店舗で個人情報登録をしたお客様。\n登録割引(−500円)適用。",
    },
    {
      id: "R",
      type: "非会員(R)",
      color: "#497d37",
      des: "店舗で個人情報登録を拒否したお客様。\n割引なし。",
    },
    {
      id: "x",
      type: "ブラック(×)",
      color: "#000",
      des: "もみ徳全店立ち入り禁止のお客様。",
    },
  ],
  type_change: {
    create_shift: "スタッフ追加",
    update_shift: "勤務時間の修正",
    extra_rest_time: "休憩",
    on_leave: "休み",
    change_rest_minute: "出勤時刻の変更",
    create_ng: "NG新規",
    update_ng: "NG修正",
    delete_ng: "NG削除",
    check_in: "出勤時刻の変更",
    request_overtime: "臨時変更",
  },
  staff_name_owner: "対象",
  working_day: "対象日",
  add_math: "追加",
  reception: "受付",
  search_name_phone: "おなまえ・電話番号",
  fixed_time_booking: "確定時間",
  coupon_tax_discount: "-10%（100円未満切り捨て）",
  reg_new_user_booking: "新規",
  config_the_point: "ポイント設定",
  point_have_provided: "ポイント額（Pt）",
  end_later_start: "終了時間は現時点より遅くしてください！",
  end_later_start2: "終了時間は開始時間より長くする必要があります",
  please_add_more_receive: "受信者を追加してください。",
  search_name_furi_phone: "名前・ふりがな・電話番号で検索する",
  staff_want_rest: (time) => `${time}に休憩しませんか。`,
  input: "入力",
  password_manual_booking: "利用履歴入力画面",
  history_booking_manual: "施術記録",
  info_booking_manual: "予約情報",
  yes: "有",
  no: "無",
  sure_delete_booking: "削除してもよろしいでしょうか。",
  history_examine: "施術歴",
  treatment_reception: "施術受付",
  total_income: "売上合計",
  change_staff: "担当者変更",
  customer_book: "お客様名前",
  start_staff: "変更前担当者",
  changed_staff: "変更後担当者",
  no_staff_change_available: "予約受付スタッフはありません",
  detail_reservation: "予約詳細",
  delete_rest_time: "休憩時間を削除してもよろしいですか？",
  sure_update_booking: `予約の内容を変更しますが、\n問題ありませんか。`,
  copy_button: "データコピー",
  staff_ng_customer: "スタッフはお客様にNGです。",
  profit: "集計",
  sales_table: "売上集計一覧表",
  start_day: "日付",
  total_money: "売上高（円）",
  total_money2: "レジ売上高",
  total_user: "来店人数（人）",
  total_user2: "来店人数",

  total_working_min: "総出勤（分）",
  total_working_min2: "総出勤時間",

  total_course_min: "総施術（分）",
  total_course_min2: "総施術時間",
  working_percentage: "稼働率（％）",
  working_percentage2: "店舗稼働率",

  total_staff: "スタッフ（人）",
  total_staff2: "スタッフ数",
  total_staff_selected: "指名数（人）",
  total_staff_selected2: "指名総数",
  total_cancel_fee: "キャンセル料合計",
  list_cancel_fee_paid: "支払ったキャンセル料一覧",

  total_D_user: "D会員（人）",
  total_D_user2: "D会員",

  total_A_user: "A会員（人）",
  total_A_user2: "A会員",

  total_S_user: "S会員（人）",
  total_S_user2: "S会員",

  total_P_user: "P会員（人）",
  total_P_user2: "P会員",

  total_R_user: "R会員（人）",
  total_R_user2: "R会員",

  booking_today: "本日予約",
  booking_list_other_days: "予約表一覧",
  cancel_confirm: "キャンセル受け",
  cancel_booking_des: "お連れ様もキャンセルしますか。",
  refuse: "いいえ",
  yeah: "はい",
  show_revenue: "戸締り清算",
  show_revenue_day: "点検・清算",
  audio_setting: "音声設定",
  confirm_revenue_newest: {
    true: "清算",
    false: "点検",
  },

  staff_lockup: "担当スタッフ",
  newest_date_confirm: "前回確認",
  newest_staff_name: "確認スタッフ",
  cannot_change_time_booking: "予約開始時間を変更できません。",
  time_rest_want: "休憩時間",
  change_time_checkout: "退勤時刻を変更してください",
  change_work: "勤務変更",
  change_work_description:
    "※勤務延長の場合は、事前契的のスタッフが優先されます。",
  required: "必須",
  create_schedule: "予約確定",
  time_confirm_revenue: "確認時刻",
  copy_fixed_schedule: "シフトコピー",
  pay_credit: "クレジット",
  pay_cash: "現金",
  operate: "操作",
  staff_recepit: "応対スタッフ",
  type_cancel: "状況",
  time_cancel: "取消日時",
  staff_cancel_book: "キャンセル受付スタッフ",

  category_course: [
    {
      name: "うつ伏せ",
      color: "#FF9D9D",
    },
    {
      name: "もみほぐし",
      color: "#F19E60",
    },
    {
      name: "クイック",
      color: "#66FFCC",
    },
    {
      name: "足つぼ",
      color: "#FFEE5E",
    },
    {
      name: "オイル",
      color: "#BFDFBF",
    },
    {
      name: "タイ式",
      color: "#CCFF66",
    },
    { name: "ストレッチ", color: "#9DD3EB" },
    {
      name: "フェーシャル",
      color: "#BF9EEF",
    },
    {
      name: "個人スペシャル",
      color: "#FFCC33",
    },
    {
      name: "店舗限定",
      color: "#FFB9E7",
    },
  ],

  title_header_main: {
    user: "お客さま",
    coupon: "クーポン",
    myPage: "マイページ",
    store: "店舗",
    homePage: "お知らせ",
    notification: "プッシュ通知",
    review: "(新) スタッフ評価",
    review2: "スタッフ評価",
    profile: "プロフィール管理",
    staff: "スタッフ登録",
    booking: "予約管理",
    slideSetting: "スライド設定",
    gacha: "抽選会",
    pointHistory: "月間ポイント履歴",
    combo: "コース設定",
    pointReception: "ポイント受付",
    storeInfo: "マイページ",
    historyStore: "来店履歴",
    historyPoint: "ポイント履歴",
    staffSchedule: "本日予約",
    staffNotGood: "NG リスト",
    staffChangeHistory: "変更履歴",
    managerCM: "ＣＭ設定",
    notificationStaff: "スタッフ通知",
    staffManagerSchedule: "予約表一覧",
    staffFixedSchedule: "シフト一覧",
    storeFixedSchedule: "シフト一覧",
    storeProvidePoint: "ポイント追加",
    managerPointDay: "ポイント設定",
    staffScheduleManual: "施術受付",
    storeProfitHistory: "売上集計一覧表",
    cancelBookingHistory: "キャンセル履歴",
  },
};

const LOGIC = {
  shiftColor: {
    早: "#FFE1CD",
    中: "#DCE4D4",
    遅: "#CDDDFE",
  },
  coupon_time: {
    time: "time_of_day",
    day: "days_of_week",
    date: "exact_days",
    default: "default",
    birthday: "birthday",
    tax: "tax_refund",
    early: "early_booking",
    first: "first_time_booking",
  },
  booking_point: {
    dayOfWeek: "SPECIFIC_DAYS_OF_WEEK",
    range: "IN_DAY_RANGE",
    date: "SPECIFIC_DATES",
    rangeWithTime: "IN_DAY_RANGE_WITH_TIME_FRAMES",
  },

  day: {
    1: "月",
    2: "火",
    3: "水",
    4: "木",
    5: "金",
    6: "土",
    7: "日",
  },
  dayOfWeek: {
    MONDAY: "月",
    TUESDAY: "火",
    WEDNESDAY: "水",
    THURSDAY: "木",
    FRIDAY: "金",
    SATURDAY: "土",
    SUNDAY: "日",
  },
  storeCode: [
    "TM",
    "FR",
    "KS",
    "EH",
    "YK",
    "WJ",
    "OH",
    "RP",
    "TW",
    "NK",
    "NX",
    "SB",
  ],
  userType: {
    D: "D",
    A: "A",
    S: "S",
    P: "P",
    R: "R",
    X: "X",
  },
  dateFormat: "YYYY年MM月DD日",
  hour24Format: "M/DD/YY, h:mm A",
  timeFormat: "YYYY年MM月DD日 HH:mm",
  funcFormat: (e) => (e ? moment(e).format("YYYY年MM月DD日 HH:mm") : ""),
  dayFormat: "MM月DD日",
  currencyFormatter: (e) => {
    return (
      new Intl.NumberFormat("ja-JP", {
        // maximumSignificantDigits: 1,
        style: "currency",
        currency: "JPY",
      })
        .format(e)
        .substring(1) + " 円"
    );
  },
  currencyYenFormatter: (e) => {
    return new Intl.NumberFormat("ja-JP", {
      // maximumSignificantDigits: 1,
      style: "currency",
      currency: "JPY",
    }).format(e);
  },
  actionBookingType: {
    cancel_contact: STRINGS.canceled_with_contact,
    cancel_no_contact: STRINGS.cancellation_without_permission,
    cancel_by_store: STRINGS.cancellation_at_store,
    cancel_before_2hours: STRINGS.cancellation_at_store,
    done: STRINGS.cash_register,
  },
  cancelBookingType: {
    FREE: STRINGS.canceled_with_contact,
    CONTACT: STRINGS.canceled_with_contact,
    NO_CONTACT: STRINGS.cancellation_without_permission,
    BY_STORE: STRINGS.cancellation_at_store,
    BEFORE_2HOURS: STRINGS.cancellation_at_store,
  },

  customerType: {
    MEMBER_APP: "MEMBER_APP",
    MEMBER_NON_APP: "MEMBER_NON_APP",
    S_MEMBER: "S_MEMBER",
    BANNED_MEMBER: "BANNED_MEMBER",
  },
  colorCustomerType: {
    D: "#ff8000",
    A: "#ff8000",
    S: "#ff8000",
    P: "#497d37",
    R: "#497d37",
    X: "#000",
  },

  typeMail: {
    booking: "予約が取れた時",
    cancel: "キャンセルができた時",
    before: "予約の前日",
    before1Hour: "当日予約の1時間前",
  },
  coupon_share: {
    SHARED: "SHARED",
    SINGLE: "SINGLE",
  },
};

export { STRINGS, LOGIC };
